import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import styles from './AboutUs.module.scss'
import { richTextOptions } from '@components/Markdown'
import Picture from '@components/Picture'
import Text from '@components/Text'
import GradientText from '@components/GradientText'
import TeamContainer from '../IndexPage/TeamContainer'
import { parseImage, parseAnchor } from '@utils/contentful'
import { TransitionState } from 'gatsby-plugin-transition-link'
import {
  gradientColor1,
  gradientColor2,
  gradientColor3,
  gradientStop1,
  gradientStop2,
  gradientStop3,
} from '@styles/variables.module.scss'

import Layout from '@components/Layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { renderToString } from 'react-dom/server'

const cx = classnames.bind(styles)

const goToLink = url => {
  window.open(url)
}

const AboutUs = ({ data, location }) => {
  const [, setOpacityStyle] = useState()
  const handleOpacityChange = style => {
    setOpacityStyle(style)
  }

  const content = data.contentfulAboutUs

  let logos = populateLogos(content.leaders)

  const [pageDisplayed, setPageDisplayed] = useState('About Us')

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const contentString = params.get('t')
    const reroutedTab = JSON.parse(contentString)
    if (reroutedTab == 'M') {
      changePageContent('Leaders')
    } else {
      changePageContent('About Us')
    }
  }, [])

  const changePageContent = pageRequested => {
    setPageDisplayed(pageRequested)
  }

  content.companies.sort(function(a, b) {
    return a.companyName < b.companyName ? -1 : a.companyName > b.companyName ? 1 : 0
  })

  const companiesComp = documentToReactComponents(content.companiesParagraph.json, richTextOptions)
  const companiesStr = renderToString(companiesComp)

  const financialStrengthComp = documentToReactComponents(content.financialStrengthParagraph.json, richTextOptions)
  const financialStrengthStr = renderToString(financialStrengthComp)

  let propertyComps = []
  let lifeComps = []
  let healthComps = []
  let specialtyComps = []

  for (let company of content.companies) {
    if (company.insuranceCategory.includes('Property')) {
      propertyComps.push(company)
    } else if (company.insuranceCategory.includes('Life')) {
      lifeComps.push(company)
    } else if (company.insuranceCategory.includes('Health')) {
      healthComps.push(company)
    } else if (company.insuranceCategory.includes('Specialty')) {
      specialtyComps.push(company)
    }
  }

  function handleBlankString(str) {
    return str === 'blank' ? '' : str
  }

  function SplitText({ content = '' }) {
    const splitText = content.split('|').map((part, index, array) => (
      <React.Fragment key={index}>
        {part}
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ))

    return <div>{splitText}</div>
  }

  const titleContent = (marginBottom = '25px') => {
    return (
      <div style={{ marginBottom: marginBottom }}>
        <GradientText
          style={{ paddingBottom: '8px' }}
          tag="h1"
          type="h1"
          colors={[gradientColor1, gradientColor2, gradientColor3]}
          colorStops={[gradientStop1, gradientStop2, gradientStop3]}
        >
          {pageDisplayed}
        </GradientText>
      </div>
    )
  }

  const basicContent = () => {
    return (
      <div>
        <div>
          {pageDisplayed == 'About Us' ? (
            content.aboutUsImage ? (
              <div>
                <div>{titleContent('0px')}</div>
                <div className={cx('about-text-con')} style={{ marginLeft: '0px', marginTop: '20px' }}>
                  <Text tag="p" type="b1" className={cx('content-paragraph')}>
                    <SplitText content={content.aboutUsParagraph.aboutUsParagraph} />
                  </Text>
                </div>
                <div className={cx('about-con')}>
                  <div className={cx('about-img-con')}>
                    <Picture
                      className={cx('about-img')}
                      src={content.aboutUsImage.file.url}
                      alt={content.aboutUsImage.description}
                    />
                  </div>
                  <div className={cx('about-text-con')}>
                    <div style={{ marginTop: '20px' }}>
                      <GradientText
                        tag="h3"
                        type="h3"
                        colors={[gradientColor1, gradientColor2, gradientColor3]}
                        colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                      >
                        {'Our Vision'}
                      </GradientText>
                    </div>
                    <div style={{ marginTop: '20px' }}>{doAboutUsInfoContent()}</div>
                  </div>
                </div>
              </div>
            ) : (
              // If no about us image then show the subtitle
              <div>
                {titleContent()}
                <GradientText
                  tag="h3"
                  type="h3"
                  colors={[gradientColor1, gradientColor2, gradientColor3]}
                  colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                >
                  {handleBlankString(content.aboutUsSubtitle)}
                </GradientText>
              </div>
            )
          ) : (
            ''
          )}
          {pageDisplayed == 'Core Values' ? (
            content.coreValuesImage ? (
              // If core values image then show it
              <div style={{ marginBottom: '25px' }}>
                <Picture src={content.coreValuesImage.file.url} alt={content.coreValuesImage.description} />
              </div>
            ) : (
              // If no core values image then show the subtitle
              <div>
                {titleContent()}
                <GradientText
                  tag="h3"
                  type="h3"
                  colors={[gradientColor1, gradientColor2, gradientColor3]}
                  colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                >
                  {handleBlankString(content.coreValuesSubtitle)}
                </GradientText>
              </div>
            )
          ) : (
            ''
          )}
          {pageDisplayed == 'Financial Strength' ? (
            <div>
              {titleContent()}
              <div>
                <GradientText
                  tag="h3"
                  type="h3"
                  colors={[gradientColor1, gradientColor2, gradientColor3]}
                  colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                >
                  {handleBlankString(content.financialStrengthSubtitle)}
                </GradientText>
              </div>
            </div>
          ) : (
            ''
          )}
          {pageDisplayed == 'Leaders' ? (
            content.leadersImage ? (
              // If Leaders image then show it
              <div>
                <Picture src={content.leadersImage.file.url} alt={content.leadersImage.description} />
              </div>
            ) : (
              // If no Leaders image then show the subtitle
              <div>
                {titleContent('0px')}
                <GradientText
                  tag="h3"
                  type="h3"
                  colors={[gradientColor1, gradientColor2, gradientColor3]}
                  colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                >
                  {handleBlankString(content.leadersSubtitle)}
                </GradientText>
              </div>
            )
          ) : (
            ''
          )}
          {pageDisplayed == 'Companies' ? (
            content.companiesImage ? (
              // If Leaders image then show it
              <div>
                <Picture src={content.companiesImage.file.url} alt={content.companiesImage.description} />
              </div>
            ) : (
              // If no Leaders image then show the subtitle
              <div>
                {titleContent()}
                <GradientText
                  tag="h3"
                  type="h3"
                  colors={[gradientColor1, gradientColor2, gradientColor3]}
                  colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                >
                  {handleBlankString(content.companiesSubtitle)}
                </GradientText>
              </div>
            )
          ) : (
            ''
          )}
        </div>
        <div className={cx('content-paragaph-con')}>
          <Text tag="p" type="b1" className={cx('content-paragraph')}>
            {pageDisplayed === 'Leaders' ? handleBlankString(content.leadersParagraph.leadersParagraph) : ''}
            {pageDisplayed == 'Companies' ? (
              <div className={cx('')} dangerouslySetInnerHTML={{ __html: companiesStr }} />
            ) : (
              ''
            )}
            {pageDisplayed === 'Core Values' ? handleBlankString(content.coreValuesParagraph.coreValuesParagraph) : ''}
            {pageDisplayed == 'Financial Strength' ? (
              <div className={cx('')} dangerouslySetInnerHTML={{ __html: financialStrengthStr }} />
            ) : (
              ''
            )}
          </Text>
        </div>
      </div>
    )
  }

  const finishAboutUsContent = () => {
    return <div></div>
  }

  const finishFinancialStrengthContent = content => {
    return content.financialStrengthImage ? (
      // If financialStrength image exists, then show it
      <div style={{ marginBottom: '25px', marginTop: '25px' }}>
        <img src={content.financialStrengthImage.file.url} alt={content.financialStrengthImage.description} />
      </div>
    ) : null // Return null or an alternative component if there's no image
  }

  const finishLeadersContent = () => {
    return (
      <div>
        <TeamContainer
          members={content.leaders.members.map(item => ({
            ...item,
            image: item.image && parseImage(item.image),
          }))}
          header={content.leaders.header}
          subHeader={content.leaders.subHeader}
          anchor={parseAnchor(content.leaders.anchor.url)}
          logos={logos}
        />
      </div>
    )
  }

  const finishCompaniesContent = () => {
    return (
      <div>
        <div className={cx('side-scroll-con')}>
          <img className={cx('circles-img')} src="/images/ms/circles4.png" alt="Group 1001 Companies" />
          <div className={cx('all-logos-con')}>
            <div className={cx('logos-con', 'logos-con-property')}>
              {propertyComps.map(function(item, i) {
                if (item.companyName == 'Lackawanna (DIAGRAM ONLY)') {
                  item.companyName = 'Clear Spring Insurance'
                }
                return (
                  <a key={i} href={'#' + item.companyName} className={cx('top-logo-link')}>
                    <img key={i} className={cx('top-logo')} src={item.logo.file.url} alt={item.logo.description} />
                  </a>
                )
              })}
            </div>
            <div className={cx('logos-con', 'logos-con-life')}>
              {lifeComps.map(function(item, i) {
                return (
                  <a key={i} href={'#' + item.companyName} className={cx('top-logo-link')}>
                    <img key={i} className={cx('top-logo')} src={item.logo.file.url} alt={item.logo.description} />
                  </a>
                )
              })}
            </div>
            <div className={cx('logos-con', 'logos-con-health')}>
              {healthComps.map(function(item, i) {
                return (
                  <a key={i} href={'#' + item.companyName} className={cx('top-logo-link')}>
                    <img key={i} className={cx('top-logo')} src={item.logo.file.url} alt={item.logo.description} />
                  </a>
                )
              })}
            </div>
            <div className={cx('logos-con', 'logos-con-specialty')}>
              {specialtyComps.map(function(item, i) {
                return (
                  <a key={i} href={'#' + item.companyName} className={cx('top-logo-link')}>
                    <img key={i} className={cx('top-logo')} src={item.logo.file.url} alt={item.logo.description} />
                  </a>
                )
              })}
            </div>
          </div>
        </div>
        <div className={cx('companies-con')}>
          {content.companies.map(function(item, i) {
            if (item.logo.description != 'Lackawanna Insurance Group') {
              return (
                <div key={i} className={cx('companies-company-con')}>
                  <a id={item.companyName}></a>
                  <div
                    key={i}
                    className={content.hideCompanyImages ? cx('disp-none') : cx('companies-company-img-con')}
                  >
                    <img
                      className={cx('companies-company-img')}
                      src={item.marketingImage.file.url}
                      alt={item.marketingImage.description}
                    />
                  </div>
                  <div className={cx('companies-company-text-con')}>
                    <div className={cx('clickable-logo-con')} onClick={() => goToLink(item.companyWebsite.url)}>
                      <img
                        className={cx('companies-company-logo')}
                        src={item.logo.file.url}
                        alt={item.logo.description}
                      />
                    </div>
                    <div
                      className={cx('companies-company-about-text')}
                      dangerouslySetInnerHTML={{
                        __html: renderToString(documentToReactComponents(item.about.json, richTextOptions)),
                      }}
                    />
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }

  const doAboutUsInfoContent = () => {
    return (
      <div className={cx('benefits-text-con')}>
        <div style={{ marginBottom: '20px' }}>
          <Text tag="p" type="b1" className={cx('health-title')}>
            GROWTH FOR ALL. GROWTH FOR GOOD.
          </Text>
        </div>
        <div>
          {content.aboutUsInfo.map(function(item, i) {
            var descArray
            if (item.description.description) {
              descArray = item.description.description.split('|')
            }
            return (
              <div key={i} style={{ marginBottom: '20px' }}>
                <div className="content-paragraph-con" style={{ marginTop: '0px', fontWeight: 'bold' }}>
                  <Text tag="p" type="b1" style={{ fontWeight: 'bold' }}>
                    {handleBlankString(item.header)}
                  </Text>
                </div>
                <div className="content-paragraph-con" style={{ marginTop: '0px', fontWeight: 'bold' }}>
                  <Text tag="p" type="b1" style={{ fontWeight: 'bold' }}>
                    {handleBlankString(item.title)}
                  </Text>
                </div>
                <div className="content-paragraph-con" style={{ marginTop: '0px' }}>
                  {descArray.map((desc, index) => (
                    <div key={index} className={cx('content-paragraph')} style={{ minHeight: '20px' }}>
                      <Text tag="p" type="b1">
                        {desc}
                      </Text>
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const finishCoreValuesContent = () => {
    return (
      <div className={cx('content-subtitle-con')}>
        <div>
          {content.coreValues.map(function(item, i) {
            var descArray
            if (item.description.description) {
              descArray = item.description.description.split('|')
            }
            return (
              <div key={i} className={cx('div-with-bottom-right-shadow-small')}>
                <div className={cx('coreValuesHeader')}>
                  <Text tag="p" type="h4" className={cx('content-paragraph')}>
                    <GradientText
                      tag="p"
                      className={cx('content-subtitle')}
                      style={{ padding: '0px', margin: '0px' }}
                      type="h4"
                      colors={[gradientColor1, gradientColor2, gradientColor3]}
                      colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                    >
                      {item.header}
                    </GradientText>
                  </Text>
                </div>
                <div className="content-paragraph-con" style={{ marginTop: '10px', fontWeight: 'bold' }}>
                  <Text tag="p" type="b1" style={{ fontWeight: 'bold' }}>
                    {item.title}
                  </Text>
                </div>
                <div className="content-paragraph-con" style={{ marginTop: '0px' }}>
                  {descArray.map((desc, index) => (
                    <div key={index} className={cx('content-paragraph')} style={{ minHeight: '20px' }}>
                      <Text tag="p" type="b1">
                        {desc}
                      </Text>
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const showBottomLinks = () => {
    if (pageDisplayed == 'Leaders') {
      return (
        <div className={cx('bottom-links')}>
          <div className={cx('bottom-links-left')} onClick={() => changePageContent('Core Values')}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'< Core Values'}
            </Text>
          </div>
          {content.showCompanies ? (
            <div className={cx('bottom-links-right')} onClick={() => changePageContent('Companies')}>
              <Text tag="p" type="b1" className={cx('bottom-links-text')}>
                {'Companies >'}
              </Text>
            </div>
          ) : content.showFinancialStrength ? (
            <div className={cx('bottom-links-right')} onClick={() => changePageContent('Financial Strength')}>
              <Text tag="p" type="b1" className={cx('bottom-links-text')}>
                {'Financial Strength >'}
              </Text>
            </div>
          ) : (
            <div className={cx('bottom-links-right')} onClick={() => changePageContent('About Us')}>
              <Text tag="p" type="b1" className={cx('bottom-links-text')}>
                {'About Us >'}
              </Text>
            </div>
          )}
        </div>
      )
    } else if (pageDisplayed == 'Companies') {
      return (
        <div className={cx('bottom-links')}>
          <div className={cx('bottom-links-left')} onClick={() => changePageContent('Leaders')}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'< Leaders'}
            </Text>
          </div>
          <div className={cx('bottom-links-right')} onClick={() => changePageContent('Financial Strength')}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'Financial Strength >'}
            </Text>
          </div>
        </div>
      )
    } else if (pageDisplayed == 'About Us') {
      return (
        <div className={cx('bottom-links')}>
          {content.showCompanies ? (
            <div className={cx('bottom-links-left')} onClick={() => changePageContent('Companies')}>
              <Text tag="p" type="b1" className={cx('bottom-links-text')}>
                {'< Companies'}
              </Text>
            </div>
          ) : content.showFinancialStrength ? (
            <div className={cx('bottom-links-left')} onClick={() => changePageContent('Financial Strength')}>
              <Text tag="p" type="b1" className={cx('bottom-links-text')}>
                {'< Financial Strength'}
              </Text>
            </div>
          ) : (
            <div className={cx('bottom-links-left')} onClick={() => changePageContent('Leaders')}>
              <Text tag="p" type="b1" className={cx('bottom-links-text')}>
                {'< Leaders'}
              </Text>
            </div>
          )}
          <div className={cx('bottom-links-right')} onClick={() => changePageContent('Core Values')}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'Core Values >'}
            </Text>
          </div>
        </div>
      )
    } else if (pageDisplayed == 'Financial Strength') {
      return (
        <div className={cx('bottom-links')}>
          {content.showCompanies ? (
            <div className={cx('bottom-links-left')} onClick={() => changePageContent('Companies')}>
              <Text tag="p" type="b1" className={cx('bottom-links-text')}>
                {'< Companies'}
              </Text>
            </div>
          ) : (
            <div className={cx('bottom-links-left')} onClick={() => changePageContent('Leaders')}>
              <Text tag="p" type="b1" className={cx('bottom-links-text')}>
                {'< Leaders'}
              </Text>
            </div>
          )}
          <div className={cx('bottom-links-right')} onClick={() => changePageContent('About Us')}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'About Us >'}
            </Text>
          </div>
        </div>
      )
    } else if (pageDisplayed == 'Core Values') {
      return (
        <div className={cx('bottom-links')}>
          <div className={cx('bottom-links-left')} onClick={() => changePageContent('About Us')}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'< About Us'}
            </Text>
          </div>
          <div className={cx('bottom-links-right')} onClick={() => changePageContent('Leaders')}>
            <Text tag="p" type="b1" className={cx('bottom-links-text')}>
              {'Leaders >'}
            </Text>
          </div>
        </div>
      )
    }

    return (
      <div className={cx('bottom-links')}>
        <div className={cx('bottom-links-left')} onClick={() => changePageContent('Companies')}>
          <Text tag="p" type="b1" className={cx('bottom-links-text')}>
            {'< Companies'}
          </Text>
        </div>
        <div className={cx('bottom-links-right')} onClick={() => changePageContent('Core Values')}>
          <Text tag="p" type="b1" className={cx('bottom-links-text')}>
            {'Core Values >'}
          </Text>
        </div>
      </div>
    )
  }

  return (
    <TransitionState>
      {({ transitionStatus } = {}) => {
        return (
          <Layout
            className={transitionStatus}
            onOpacityChange={handleOpacityChange}
            seo={{ title: content.headerText.replace(/\|/g, ' ') }}
            smoothScroll={true}
          >
            <div className={cx('page-con')}>
              <div className={cx('hero-con')}>
                <div className={cx('hero-title-con')}>
                  <GradientText
                    tag="h1"
                    className={cx('hero-title')}
                    type="h1"
                    colors={[gradientColor1, gradientColor2, gradientColor3]}
                    colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                  >
                    {<SplitText content={content.headerText} />}
                  </GradientText>
                  {content.headerSubtitle !== 'blank' && (
                    <div className={cx('content-subtitle-con')}>
                      <GradientText
                        tag="h3"
                        className={cx('content-subtitle')}
                        type="h3"
                        colors={[gradientColor1, gradientColor2, gradientColor3]}
                        colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                      >
                        {handleBlankString(content.headerSubtitle)}
                      </GradientText>
                    </div>
                  )}
                  {content.headerParagraph.headerParagraph !== 'blank' && (
                    <div className={cx('content-paragraph-con')}>
                      <Text tag="p" type="b1" className={cx('content-paragraph')}>
                        {content.headerParagraph.headerParagraph}
                      </Text>
                    </div>
                  )}
                </div>
                <div className={cx('hero-img-con')}>
                  <Picture
                    className={cx('hero-img')}
                    src={content.headerImage.file.url}
                    alt={content.headerImage.description}
                  />
                </div>
              </div>

              <div className={cx('nav-con')}>
                <div className={cx('nav-link-con')} onClick={() => changePageContent('About Us')}>
                  <Text
                    tag="p"
                    type="h3"
                    className={pageDisplayed == 'About Us' ? cx('nav-link', 'nav-link-underline') : cx('nav-link')}
                  >
                    {'About Us'}
                  </Text>
                </div>
                <div className={cx('nav-link-con')} onClick={() => changePageContent('Core Values')}>
                  <Text
                    tag="p"
                    type="h3"
                    className={pageDisplayed == 'Core Values' ? cx('nav-link', 'nav-link-underline') : cx('nav-link')}
                  >
                    {'Core Values'}
                  </Text>
                </div>
                <div className={cx('nav-link-con')} onClick={() => changePageContent('Leaders')}>
                  <Text
                    tag="p"
                    type="h3"
                    className={pageDisplayed == 'Leaders' ? cx('nav-link', 'nav-link-underline') : cx('nav-link')}
                  >
                    {'Leaders'}
                  </Text>
                </div>
                {content.showFinancialStrength ? (
                  <div className={cx('nav-link-con')} onClick={() => changePageContent('Financial Strength')}>
                    <Text
                      tag="p"
                      type="h3"
                      className={
                        pageDisplayed == 'Financial Strength' ? cx('nav-link', 'nav-link-underline') : cx('nav-link')
                      }
                    >
                      {'Financial Strength'}
                    </Text>
                  </div>
                ) : (
                  ''
                )}
                {content.showCompanies ? (
                  <div className={cx('nav-link-con')} onClick={() => changePageContent('Companies')}>
                    <Text
                      tag="p"
                      type="h3"
                      className={pageDisplayed == 'Companies' ? cx('nav-link', 'nav-link-underline') : cx('nav-link')}
                    >
                      {'Companies'}
                    </Text>
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className={cx('page-content-con')}>
                {basicContent()}
                {pageDisplayed == 'Leaders' ? finishLeadersContent() : ''}
                {pageDisplayed == 'About Us' ? finishAboutUsContent() : ''}
                {pageDisplayed == 'Companies' ? finishCompaniesContent() : ''}
                {pageDisplayed == 'Core Values' ? finishCoreValuesContent() : ''}
                {pageDisplayed == 'Financial Strength' ? finishFinancialStrengthContent(content) : ''}
                {showBottomLinks()}
              </div>
            </div>
          </Layout>
        )
      }}
    </TransitionState>
  )
}

function populateLogos(contentfulHomeTeam) {
  let logos = []
  logos.push(contentfulHomeTeam.logoA)
  logos.push(contentfulHomeTeam.logoB)
  logos.push(contentfulHomeTeam.logoC)
  logos.push(contentfulHomeTeam.logoD)
  logos.push(contentfulHomeTeam.logoE)
  logos.push(contentfulHomeTeam.logoF)
  logos.push(contentfulHomeTeam.logoG)
  return logos
}

AboutUs.propTypes = {
  data: PropTypes.object,
}

export default AboutUs
